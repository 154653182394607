import { render, staticRenderFns } from "./licenzaScaduta.vue?vue&type=template&id=64d6b092&scoped=true&"
import script from "./licenzaScaduta.vue?vue&type=script&lang=js&"
export * from "./licenzaScaduta.vue?vue&type=script&lang=js&"
import style0 from "./licenzaScaduta.vue?vue&type=style&index=0&id=64d6b092&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64d6b092",
  null
  
)

export default component.exports